import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Corgi from "./Corgi";
import Privacy from "./Privacy";
import Support from "./Support";
import reportWebVitals from "./reportWebVitals";
import Terms from "./Terms";
import Pricing from "./Pricing";
import AuthSuccess from "./AuthSuccess";
import AuthFailure from "./AuthFailure";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/corgi",
    element: <Corgi />,
  },
  {
    path: "/corgi/privacy",
    element: <Privacy />,
  },
  {
    path: "/corgi/support",
    element: <Support />,
  },
  {
    path: "/corgi/terms",
    element: <Terms />,
  },
  {
    path: "/corgi/pricing",
    element: <Pricing />,
  },
  {
    path: "/corgi/success",
    element: <AuthSuccess />,
  },
  {
    path: "/corgi/auth-error",
    element: <AuthFailure />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
