import Nav from "./NavBar";
import screenshot from "./corgi-sample.png";
import "./Corgi.css";

function Support() {
  return (
    <>
      <header className="App-header-corgi">
        <Nav />
      </header>
      <div className="main-content">
        <p>
          <b>FAQ</b>
          <br />
          <br />
          <b>Why are my chat requests taking so long to respond?</b>
          <br />
          Corgi utilizes OpenAI APIs to process your requests, some request take
          more time to process. Generally the longer the answer the longer it
          takes to process the request. <br />
          <br />
          <b>My requests are failing?</b>
          <br />
          This could be either caused by OpenAI APIs being unable to process
          your request or something wrong on our end. Please try again at a
          later time or contact us with details at support@happybit.io and we'll
          happily investigate further <br />
          <br />
          <b>
            I'm getting a message back that I've reached my monthly limit, why?
          </b>
          <br />
          By default you get 200 request per month, if you go above that limit
          we will no longer be able to process your request. If you'd like to
          increase this limit please contact us at sales@happybit.io and we'd be
          happy to help you out. <br />
          <br />
          <b>Contact Us:</b> If you have any other concerns or questions please
          contact us via support@happybit.io
        </p>
      </div>
    </>
  );
}

export default Support;
