import Nav from "./NavBar";
import "./Corgi.css";

function Pricing() {
  return (
    <>
      <header className="App-header-corgi">
        <Nav />
      </header>
      <div className="main-content">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <p>
              <b>Free plan:</b> Up to 200 requests per month - $0
            </p>

            <p>
              <b>Basic:</b> Up to 5,000 requests per month - $20
            </p>

            <p>
              <b>Advanced:</b> Up to 10,000 requests per month - $40
            </p>

            <p>
              <b>Custon:</b> Set your own limit - Contact us
            </p>
          </div>
          <div>
            <p>Please contact sales@happybit.io to change your plan</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
