import Nav from "./NavBar";
import "./Corgi.css";

function Corgi() {
  return (
    <>
      <header className="App-header-corgi">
        <Nav />
      </header>
      <div className="main-content">
        <p>
          <b>Privacy Policy At Corgi AI Assistant</b>
          <br />
          <br />
          We are committed to protecting the privacy of our users. This privacy
          policy applies to the chat bot we have developed using OpenAI, which
          is designed to provide information and support to users. <br /> <br />
          <b>Collection of Information:</b> <br /> The chat bot operates as a
          conversational interface that provides answers to user questions. Our
          chat bot does not collect or store any personal information from
          users, including user prompts. We might retain some meta data
          information for debugging purposes in case of system failures. This
          data will not be retained for more than 14 days and purged afterwards.
          <br />
          <br />
          In addition, in order to be able to communicate with your Slack
          workspace we need to store access tokens provided by Slack to us after
          user authentication is completed. We store this data encrypted within
          our nosql database and it's only accessible by services that need to
          read this data, you can request this data to be purged by following
          the instructions listed under data removal policy.
          <br />
          <br /> <b>Data archival/removal policy: </b>
          <br />
          Please email us at support@happybit.io to have your data removed or
          archived. Requests will be processed within 14 days. <br />
          <br /> <b>Use of Information: </b>
          <br />
          The information provided by our chat bot is intended solely for
          general informational purposes and is not intended to replace
          professional advice. We do not sell, share, or otherwise disclose any
          information collected through the chat bot to third parties. <br />
          <br /> <b>Data storage policy: </b>
          <br />
          Making sure your data is safe and secure is our number one priority.
          Credentials that you use to connect your accounts to Corgi are
          protected with bank-level encryption. The requests that the user makes
          are not in any way stored within our system. We might retain some meta
          data information such as error messages recieved when processing
          requests for debugging purposes in case of system failures. This data
          will not be retained for more than 14 days and purged afterwards.{" "}
          <br />
          <br />
          <b>Security Measures:</b>
          <br />
          We take the security of user information seriously and have
          implemented appropriate technical and organizational measures to
          protect user information. However, no system can be completely secure
          and we cannot guarantee the security of user information. <br />
          <br />
          <b>Changes to This Privacy Policy:</b>
          <br /> We may update this privacy policy from time to time to reflect
          changes in our practices or services. We will notify you of any
          material changes to this privacy policy by posting the updated policy
          on our website. <br />
          <br />
          <b>Contact Us:</b> If you have any questions or concerns about this
          privacy policy, please contact us at privacy@happybit.ai. This privacy
          policy is effective as of Feb 2nd, 2023.
        </p>
      </div>
    </>
  );
}

export default Corgi;
