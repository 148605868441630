import { Helmet } from "react-helmet";
import Nav from "./NavBar";
import screenshot from "./corgi-sample.png";
import "./Corgi.css";

function Corgi() {
  return (
    <>
      <header className="App-header-corgi">
        <Nav />
      </header>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Corgi AI Assitant</title>
        <meta
          name="description"
          content="Corgi ChatGPT powered Slack chatbot"
        />
      </Helmet>
      <div className="main-content">
        <img
          src={screenshot}
          className="corgi-sample"
          alt="sample-screenshot"
        />
        <div className="intro-section">
          <h2>Get all your questions answered!</h2>
          <p>
            Introducing Corgi, an AI-powered Slack assistant designed to make
            your work life easier and more efficient. Transform your workplace
            communication with ChatGPT, the ultimate conversational bot for
            Slack that delivers quick, accurate, and human-like responses.
          </p>
          <div className="privacy-policy-link">
            <a href="/corgi/privacy">Privacy policy</a>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <a
          href="https://slack.com/oauth/v2/authorize?client_id=4707967830103.4722547414898&scope=chat:write,commands,im:history&user_scope="
          style={{
            alignItems: "center",
            color: "#fff",
            backgroundColor: "#4A154B",
            border: 0,
            borderRadius: "4px",
            display: "inline-flex",
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: 600,
            height: "56px",
            justifyContent: "center",
            textDecoration: "none",
            width: "276px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            marginBottom: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: "24px", width: "24px", marginRight: "12px" }}
            viewBox="0 0 122.8 122.8"
          >
            <path
              d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
              fill="#e01e5a"
            ></path>
            <path
              d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
              fill="#36c5f0"
            ></path>
            <path
              d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
              fill="#2eb67d"
            ></path>
            <path
              d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
              fill="#ecb22e"
            ></path>
          </svg>
          Add to Slack
        </a>
      </div>
    </>
  );
}

export default Corgi;
